import React, { useRef, useEffect, useState } from 'react';
import { StyleSheet, View, Text, Button, TouchableOpacity, Image, ScrollView, Linking, Platform, Dimensions, Alert } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import { commonConfig } from '../components/config';
import { AntDesign, EvilIcons, Feather } from '@expo/vector-icons';
import SpliteLine from '../components/spliteLine';
import AsyncStorage from '@react-native-community/async-storage';
import { getWebsiteInfo, getSymbolPrice, getHuobiPrice, getKline, getKlineAll, getKline2All } from '../api/apis';
import { navigateRequireLogin, virtual, timeConversion } from '../components/util';
import NetInfo from "@react-native-community/netinfo";
import TextTicker from 'react-native-text-ticker';
import { commonLang } from '../components/config';
import Carousel from '../components/Carousel';
import DraggableButton from '../components/draggableButton';

import config from '../../config'; 

let Swiper
if (Platform.OS !== 'web') {
    Swiper = require('react-native-swiper');
}

const Home = () => {

    const iframeRef = useRef(null);

    const navigation = useNavigation();
    const { i18n, t } = useTranslation();
    const [isLogin, setIsLogin] = useState(false);
    const [websiteInfo, setWebsiteInfo] = useState(false);
    const [curr, setCurr] = useState('USD');
    const [banner, setBanner] = useState([]);
    const [announcement, setAnnouncement] = useState([])
    const [announcementIndex, setAnnouncementIndex] = useState(0);
    const [langSelect, setLangSelect] = useState(false);
    const [domain, setDomain] = useState('');
    const [staticDomain, setStaticDomain] = useState('');
    const [appDown, setAppDown] = useState('');

    const initialData = Object.keys(virtual());

    const initialDataList = initialData.map((currency) => ({
        currency,
        amount: '--',
        percentage: '--',
    }));

    const [dataList, setDataList] = useState(initialDataList);
    const [dataList2, setDataList2] = useState(false);

    useEffect(() => {

        updateDataList2()
        updateDataList()
        fetchAllData()

        const initFunc = async () => {
            await init();

            if (Platform.OS !== 'web') {
                const unsubscribe = NetInfo.addEventListener((state) => {
                    if (!state.isConnected) {
                        // 无网络连接，跳转到另一个页面
                        navigation.navigate('NetWork');
                    }
                });

                return () => {
                    unsubscribe();
                };
            }
        };


        initFunc();

        return () => {

        };
    }, []);


    useEffect(() => {

        const intervalId = setInterval(updateDataListPrice, 1500);
        return () => {
            clearInterval(intervalId);
        };

    }, [dataList, dataList2]);


    useEffect(() => {
        const intervalId = setInterval(fetchAllData, 20000);
        return () => {
            clearInterval(intervalId);
        };

    }, [dataList, dataList2]);


    useFocusEffect(
        React.useCallback(() => {
            updateDataList()
            fetchAllData()
        }, [])
    );

    const updateDataList = async () => {

        console.log('updateDataList')

        const kline = await getKlineAll();

        if (kline) {
            const updatedDataList = dataList.map((data) => {
                const klineData = kline.find((item) => item.symbol === data.currency);
                if (klineData) {

                    const { price, change_percentage } = klineData;

                    return {
                        ...data,
                        amount: price,
                        percentage: change_percentage,
                    };
                }
                return data;
            });

            setDataList(updatedDataList);
        }

    };

    const updateDataList2 = async () => {
        const kline = await getKline2All();
        if (kline) {
            setDataList2(kline);
        }
    };

    const updateDataListPrice = async () => {
        if (dataList2) {
            const updatedDataList = dataList.map((data) => {
                const currencyData = dataList2[data.currency];
                if (currencyData && currencyData.length > 0) {
                    const randomIndex = Math.floor(Math.random() * currencyData.length);
                    const selectedData = currencyData[randomIndex];
                    const { price } = selectedData;

                    return {
                        ...data,
                        amount: price,
                        percentage: data.percentage2 ? data.percentage2 : data.percentage
                    };

                }
                return data;
            });

            // console.log(updatedDataList)

            setDataList(updatedDataList);
        }
    };


    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const fetchAllData = async () => {
        const updatedDataList = await Promise.all(
            dataList.map(async (item, index) => {
                try {
                    // 设置每个请求之间的延迟，例如，每个请求之间等待毫秒
                    await delay(index * 500);
                    const huobiPrice = await getHuobiPrice(item.currency);
                    if (huobiPrice) {
                        item.amount = huobiPrice;
                    }

                    const kline = await getKline(item.currency, '1day', 2);
                    if (kline) {
                        item.count = kline.klineData[0].count;
                        item.percentage = kline.changePercentage;
                        item.percentage2 = kline.changePercentage;
                    }

                    return item;
                } catch (error) {
                    console.error(error);
                    return item;
                }
            })
        );
        setDataList(updatedDataList);
    };


    const isLoggedIn = async () => {
        if (await AsyncStorage.getItem('userinfo')) {
            setIsLogin(true)
        } else {
            setIsLogin(false)
        }
    }


    //删除弹窗
    const delNotice = async (click_del_notice_random) => {
        await AsyncStorage.setItem('click_del_notice', click_del_notice_random);
    }


    const init = async () => {

        if (Platform.OS == 'web' && !isLogin) {
            //分享过来的的链接
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());

            if (params.screen && params.screen == 'register' && params.invitation_code) {
                navigation.navigate('Register');
                return;
            }
        }

        // console.log('home init')
        const response = await getWebsiteInfo();
        if (response.status == '1') {
            // console.log(response.Index_Data)
            // console.log(response.Data)
            await AsyncStorage.setItem('websiteInfo', JSON.stringify(response));
            setWebsiteInfo(response)
            setBanner(response.Index_Data.swiper_data_)
            setAnnouncement(response.Index_Data.announcement)
            setDomain(response.Data.default_domain)
            setStaticDomain(response.Data.static)
            setAppDown(response.Data.alAndroid)

            // await AsyncStorage.setItem('click_del_notice', '');

            // 弹窗公告
            const datav = response.Index_Data
            if (datav.isShowWin.length > 0) {
                if (datav.isShowWin[0].status == "1") {
                    let a = timeConversion(datav.isShowWin[0].time_year);
                    const ymd = `${a.Y}-${a.M}-${a.D}`;

                    let last_click_del_notice_ = await AsyncStorage.getItem('click_del_notice')
                    if (last_click_del_notice_ != datav.isShowWin[0].random) {

                        if (Platform.OS == 'web') {
                            const title = datav.isShowWin[0].title;
                            const message = datav.isShowWin[0].txtContent;

                            const confirmationMessage = `${title}\n\n${message}`;
                            const result = window.confirm(confirmationMessage);

                            if (result) {
                                delNotice(datav.isShowWin[0].random)
                            } else {
                                delNotice(datav.isShowWin[0].random)
                            }
                        } else {
                            Alert.alert(
                                datav.isShowWin[0].title,
                                datav.isShowWin[0].txtContent,
                                [
                                    { text: t('cancel'), style: 'cancel', onPress: () => delNotice(datav.isShowWin[0].random) },
                                    { text: t('confirm'), onPress: () => delNotice(datav.isShowWin[0].random) }
                                ],
                                { cancelable: false }
                            );
                        }

                    }
                }
            }


            if (response.Data.curr != "") {
                // setCurr(response.Data.curr)
            }
            // const interval = setInterval(() => {
            //     setAnnouncementIndex((prevIndex) => {
            //         const nextIndex = prevIndex + 1;
            //         if (nextIndex >= response.Index_Data.announcement.length) {
            //             return 0; // 回到第一条公告
            //         }
            //         return nextIndex;
            //     });
            // }, 3000); // 每隔3秒切换公告

            // return () => {
            //     clearInterval(interval); // 清除定时器
            // };
        }
    }

    const goToLanguageScreen = () => {
        setLangSelect(!langSelect);
        // navigation.navigate('Language');
    }

    const handleLanguageSelect = (language) => {
        // console.log(language)
        i18n.changeLanguage(language)
        setLangSelect(false)
    };

    // const goToLoginScreen = () => {
    //     navigation.navigate('Login');
    // }

    // const goToMyScreen = () => {
    //     navigation.navigate('My');
    // }

    const goToExchangeScreen = (amount, currency) => {
        const route = {
            name: 'Exchange', // 路由地址
            params: { amount, currency: currency },
        };
        navigateRequireLogin(navigation, route);
    }

    // const goToMessageNotificationsScreen = () => {
    //     const route = {
    //         name: 'MessageNotifications', // 路由地址
    //         // params: { userId: '123' }, // 路由参数
    //         // screen: 'Home'
    //     };
    //     navigateRequireLogin(navigation, route);
    // }

    const handleBannerClick = (link) => {
        if (link != '') {
            Linking.openURL(link);
        }
    };

    const goToDownScreen = () => {
        if (appDown != '') {
            Linking.openURL(appDown);
        }
    }

    //轮播图
    const swiperRef = useRef(null);


    const adjustIframeHeight = () => {
        // 获取 iframe 内容的实际高度
        const contentHeight = iframeRef.current.contentWindow.document.body.scrollHeight;

        // 设置 iframe 的高度
        iframeRef.current.style.height = `${contentHeight}px`;
    };


    const imageSlides = banner.map((item, index) => `
    <div class="swiper-slide">
      ${item.link ? `<a href="${item.link}" target="_blank">` : ''}
      <img src="${staticDomain + '/' + item.image}" alt="Slide ${index + 1}">
      ${item.link ? '</a>' : ''}
    </div>
  `).join('');


    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link rel="stylesheet" href="https://unpkg.com/swiper/swiper-bundle.min.css">
        <style>
            html,
            body {
                position: relative;
                height: 100%;
                overflow: hidden;
                margin: 0;
            }

            body {
                background: #121420;
                font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
                font-size: 14px;
                color: #000;
                margin: 0;
                padding: 0;
            }
            .swiper {
                width: 100%;
                height: 100%;
            }

            .swiper-slide {
                text-align: center;
                font-size: 18px;
                background: #121420;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .swiper-slide img {
                display: block;
                width: 100%;
                height: 100%;
               
            }
        </style>
    </head>

    <body>
        <div class="swiper-container">
            <div class="swiper-wrapper">
            ${imageSlides}
            </div>
        </div>

        <script src="https://unpkg.com/swiper/swiper-bundle.min.js"></script>
        <script>

            document.addEventListener("DOMContentLoaded", function () {
                const mySwiper = new Swiper('.swiper-container', {
                    // 自动轮播配置
                    autoplay: {
                        delay: 3000, // 自动轮播间隔，单位为毫秒
                    },

                });
            });

        </script>
    </body>

    </html>

    `

    return (
        <View style={styles.container}>
            <DraggableButton />
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={styles.contentContainer}
            >
                <View >
                    <View style={styles.header}>
                        <View>
                            <TouchableOpacity onPress={goToLanguageScreen}>
                                <Image style={styles.icon} source={require('../../assets/imgs/home/global.png')} />
                            </TouchableOpacity>

                            {langSelect &&
                                <View style={{ backgroundColor: 'rgba(18, 20, 32, 0.9)', width: 85, position: 'absolute', right: -60, top: 40, zIndex: 999 }}>
                                    {Object.keys(commonLang.lang).map((key) => (
                                        <TouchableOpacity
                                            key={key}
                                            onPress={() => handleLanguageSelect(key)}
                                            style={[
                                                styles.languageItem,

                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    styles.languageText,

                                                ]}
                                            >
                                                {commonLang.lang[key]}
                                            </Text>
                                        </TouchableOpacity>
                                    ))}
                                </View>
                            }
                        </View>
                        <Image style={config.logoStyle} source={require('../../assets/logo.png')} />
                        <View style={styles.loginContainer}>
                            {/* {isLogin && <TouchableOpacity onPress={goToMyScreen}>
                                <Image style={styles.icon} source={require('../../assets/imgs/tabs/my.png')} />
                            </TouchableOpacity>}
                            {!isLogin && <TouchableOpacity onPress={goToLoginScreen}>
                                <Text style={styles.loginText}>{t('login')}</Text>
                            </TouchableOpacity>} */}

                            {Platform.OS != 'web' && <TouchableOpacity onPress={goToDownScreen}>
                                <Feather name="download-cloud" size={22} color="#fff" style={{ marginRight: 5 }} />
                            </TouchableOpacity>}
                            {Platform.OS == 'web' &&
                                <a href={appDown} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}  >
                                    <Feather name="download-cloud" size={22} color="#fff" style={{ marginRight: 5 }} />
                                </a>}
                        </View>

                    </View>

                    {banner.length > 0 && Platform.OS === 'web' &&
                        <iframe
                            ref={iframeRef}
                            srcDoc={htmlContent}
                            width="100%"
                            style={{ border: '0px', height: 'auto' }} // 设置初始高度为 auto，以适应内容
                            onLoad={adjustIframeHeight}
                        ></iframe>
                        // <View style={{ zIndex: -999 }}>
                        //     <Carousel data={banner} domain={staticDomain + '/'}   />
                        // </View>
                    }

                    {banner.length > 0 && Swiper && <View style={styles.swiperContainer}>
                        <Swiper
                            ref={swiperRef}
                            loop={true}
                            autoplay={true}
                            showsPagination={false} //不显示圆点
                            showsButtons={false}  //为false时不显示控制按钮
                            autoplayTimeout={4}
                            style={styles.swiper}
                        >
                            {banner.map((item, index) => (
                                <TouchableOpacity style={styles.slide} key={index} onPress={() => handleBannerClick(item.link)}>
                                    <Image style={styles.image} source={{ uri: staticDomain + '/' + item.image }} />
                                </TouchableOpacity>
                            ))}
                        </Swiper>
                    </View>}

                    <View style={styles.sound}>
                        <AntDesign name="sound" size={18} color="#0ECB81" style={{ marginTop: 12 }} />
                        <View style={styles.soundTextContainer}>

                            {Platform.OS !== 'web' && <View numberOfLines={1} ellipsizeMode="clip" style={{ width: '88%', position: 'relative', overflow: 'hidden' }}>
                                <TextTicker
                                    style={{ color: '#fff' }}
                                    duration={8000}
                                    loop
                                    bounce
                                    repeatSpacer={50}
                                    marqueeOnMount
                                >
                                    {announcement.length > 0 && announcement[0].title}

                                </TextTicker>
                            </View>}

                            {Platform.OS == 'web' && <View style={{ flex: 1, marginLeft: 10, marginTop: 3 }}>
                                <Text numberOfLines={1} ellipsizeMode="clip" style={{ color: '#fff' }}>{announcement.length > 0 && announcement[0].title}</Text>
                            </View>}

                            {/* <Text style={styles.soundText}>{announcement.length > 0 && announcement[0].title}</Text> */}
                        </View>
                        <TouchableOpacity style={{ marginTop: 12 }} onPress={() => navigateRequireLogin(navigation, { name: 'Message', params: { title: t('systemAnnouncement'), type: 1 } })}>
                            {/* <TouchableOpacity onPress={() => navigateRequireLogin(navigation, { name: 'MessageNotifications' })}> */}
                            <EvilIcons name="navicon" size={20} color="#fff" />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.rowContainerBox}>
                        <View style={styles.rowContainer}>

                            {dataList.slice(0, 3).map((item, index) => (
                                <TouchableOpacity onPress={() => goToExchangeScreen(item.amount, item.currency, item.percentage)} key={index}>
                                    <View style={styles.columnContainer} key={index}>
                                        <View style={styles.row}>
                                            <Text style={styles.text}>{item.currency}</Text>
                                            <Text style={[styles.greyText, { lineHeight: 24 }]}>/{curr}</Text>
                                        </View>
                                        <Text style={[item.percentage > 0 ? styles.greenText : styles.redText, { fontSize: 16 }]}>{item.amount}</Text>
                                        <Text style={[item.percentage > 0 ? styles.greenText : styles.redText, { fontSize: 14 }]}>{item.percentage}{item.percentage != '--' && '%'}</Text>
                                    </View>
                                </TouchableOpacity>
                            ))}
                        </View>
                    </View>
                    <View style={styles.listContainer}>
                        <View style={styles.listTitle}>
                            <Text style={styles.listTitleText}>{t('exchange')}</Text>
                            <Text style={styles.listTitleText}>{t('latestPrice')}</Text>
                            <Text style={styles.listTitleText}>24h</Text>
                        </View>
                        <SpliteLine />
                        <View>
                            <View style={{ flex: 1, justifyContent: 'space-between' }}>
                                {dataList.map((item, index) => (
                                    <TouchableOpacity onPress={() => goToExchangeScreen(item.amount, item.currency, item.percentage)} key={index}>
                                        <View style={styles.rowItem}>
                                            <View style={styles.flexRow}>
                                                {item.currency == 'BTC' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/BTC.png')} />
                                                }
                                                {item.currency == 'ETH' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/ETH.png')} />
                                                }
                                                {item.currency == 'BCH' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/BCH.png')} />
                                                }
                                                {item.currency == 'UNI' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/UNI.png')} />
                                                }
                                                {item.currency == '1INCH' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/1INCH.png')} />
                                                }
                                                {item.currency == 'LTC' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/LTC.png')} />
                                                }
                                                {item.currency == 'XRP' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/XRP.png')} />
                                                }
                                                {item.currency == 'ETC' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/ETC.png')} />
                                                }
                                                {item.currency == 'DOGE' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/DOGE.png')} />
                                                }
                                                {item.currency == 'FIL' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/FIL.png')} />
                                                }
                                                {item.currency == 'UMA' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/UMA.png')} />
                                                }
                                                {item.currency == 'AAVE' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/AAVE.png')} />
                                                }
                                                {item.currency == 'BAL' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/BAL.png')} />
                                                }
                                                {item.currency == 'CHZ' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/CHZ.png')} />
                                                }
                                                {item.currency == 'HT' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/HT.png')} />
                                                }
                                                {item.currency == 'TRX' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/TRX.png')} />
                                                }
                                                {item.currency == 'EOS' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/EOS.png')} />
                                                }
                                                {item.currency == 'ADA' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/ADA.png')} />
                                                }
                                                {item.currency == 'USDC' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/USDC.png')} />
                                                }
                                                {item.currency == 'DOT' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/Polkadot.png')} />
                                                }
                                                {item.currency == 'XMR' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/XMR.png')} />
                                                }
                                                {item.currency == 'BSV' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/BSV.png')} />
                                                }
                                                {item.currency == 'DASH' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/DASH.png')} />
                                                }
                                                <Text style={styles.itemCurrency}>{item.currency}</Text>
                                                <Text style={styles.greyText}>/{curr}</Text>
                                            </View>
                                            <View style={{ flex: 1, alignItems: 'center' }}>
                                                <View style={{ width: 100 }}>
                                                    <Text style={styles.itemMoney}>{item.amount}</Text>
                                                </View>

                                            </View>
                                            <View
                                                style={item.percentage >= 0 ? styles.itemGreenContainer : styles.itemRedContainer}
                                            >
                                                <Text style={item.percentage >= 0 ? styles.itemGreenText2 : styles.itemRedText2}>{item.percentage >= 0 && '+'}{item.percentage}{item.percentage != '--' && '%'}</Text>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollView >
        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
        paddingHorizontal: 5
    },
    contentContainer: {
        width: '100%',
        // marginTop: 10
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 10
    },
    icon: {
        width: 35,
        height: 35,
        marginTop: 10
    },
    logo: {
        width: 200,
        height: 75,
        marginBottom: -40
    },
    loginContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 7
    },
    loginText: {
        color: '#fff',
        fontSize: 16,
    },
    swiperContainer: {
        marginTop: 10,
        paddingHorizontal: 10,
    },
    swiper: {
        height: 200,
    },
    slide: {
        flex: 1,
        alignItems: 'center',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 10
    },
    sound: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 5,
        height: 50,
        zIndex: -1
    },
    soundTextContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    soundText: {
        color: '#fff',
        marginLeft: 10,
    },
    rowContainerBox: {
        flex: 1,
        backgroundColor: '#1B1D2A',
        borderRadius: 3,
        padding: 10,
        zIndex: -1
        // marginHorizontal: 5
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    columnContainer: {
        justifyContent: 'center',
    },
    row: {
        flexDirection: 'row',
    },
    text: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold'
    },
    greyText: {
        color: '#4F5665',
        fontWeight: 'bold'
    },
    greenText: {
        color: '#0ECB81',
        fontWeight: 'bold'
    },
    redText: {
        color: '#F5465C',
        fontWeight: 'bold'
    },
    rowItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
        paddingVertical: 5
    },
    flexRow: {
        flexDirection: 'row',
        width: 120,
    },
    itemMoney: {
        color: commonStyle.textWhiteColor,
        textAlign: 'left',
        fontWeight: 'bold'
    },
    itemCurrency: {
        color: '#fff',
        fontSize: 14,
        fontWeight: 'bold'
    },
    itemGreenContainer: {
        backgroundColor: '#1e363a',
        width: 65,
        borderRadius: 2,
        height: 30,
        justifyContent: 'center'
    },
    itemGreenText: {
        color: '#fff',
        textAlign: 'center',

    },
    itemRedContainer: {
        backgroundColor: '#3b2937',
        width: 65,
        borderRadius: 2,
        height: 30,
        justifyContent: 'center',
    },
    itemText: {
        color: '#fff',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    itemGreenText2: {
        color: '#3db485',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    itemRedText2: {
        color: '#f5465c',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    listContainer: {
        backgroundColor: '#1B1D2A',
        borderRadius: 3,
        padding: 10,
        marginTop: 10,
        // marginHorizontal: 5
    },
    listTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 3
    },
    listTitleText: {
        color: '#707A8A'
    },
    currencyImage: {
        borderRadius: 999,
        width: 20,
        height: 20,
        marginRight: 10
    },
    languageItem: {
        marginVertical: 5,
        marginLeft: 5,
    },
    languageText: {
        color: '#fff',
        fontSize: 15
    }
});

export default Home;
import React from 'react';
import { View } from 'react-native';
import ProgressBar from 'react-native-progress-bar-animated';

function MyProgressBar() {
  const progress = 50; // 进度值，范围为 0 到 100

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <ProgressBar
        width={200}
        height={10}
        borderRadius={5}
        borderWidth={0}
        maxValue={100}
        value={progress}
        color="#6CC644"
        backgroundColorOnComplete="#6CC644"
        backgroundColor="#CCCCCC"
      />
    </View>
  );
}

export default MyProgressBar;
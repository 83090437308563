
import AsyncStorage from '@react-native-community/async-storage';

// 跳转导航校验登录
export const navigateRequireLogin = async (navigation, route) => {
    const userinfo = await AsyncStorage.getItem('userinfo');
    const cookies = await AsyncStorage.getItem('cookies');
    if (userinfo) {
        // 已登录，跳转到传递的路由地址，并传递参数
        navigation.navigate(route.name, route.params);
    } else {
        // 未登录，跳转到登录页
        navigation.navigate('Login');
    }
};

export const isLoggedIn = async (navigation) => {
    const userinfo = await AsyncStorage.getItem('userinfo');
    const cookies = await AsyncStorage.getItem('cookies');
    if (!userinfo) {
        navigation.navigate('Login');
    }
}


export const timeConversion = (time) => {
    var nowTime = new Date(time * 1);
    var offset = nowTime.getTimezoneOffset() / 60;
    time = Number(time * 1000);
    time = time + (-offset) * 60 * 60 * 1000;
    // 设置使用中国时间
    let data = new Date(time * 1);
    let Y = data.getUTCFullYear(),
        M = data.getUTCMonth() + 1 >= 10 ? data.getUTCMonth() + 1 : "0" + (data.getUTCMonth() + 1),
        D = data.getUTCDate() >= 10 ? data.getUTCDate() : "0" + data.getUTCDate(),
        h = data.getUTCHours() <
            10 ? "0" + data.getUTCHours() : data.getUTCHours(),
        min = data.getUTCMinutes() < 10 ? "0" + data.getUTCMinutes() : data.getUTCMinutes(),
        s = data.getUTCSeconds() < 10 ? "0" + data.getUTCSeconds() :
            data.getUTCSeconds();
    time = {
        Y: Y,
        M: M,
        D: D,
        h: h,
        min: min,
        s: s
    }
    return time;
}

// vip登记
export const vipGrade = (inte) => {
    let obj = {};
    let integral = 0;
    let title = '';
    if (inte <
        2) {
        inte = "VIP1";
        integral = 2;
        title = 'grade.1';
    }
    if (inte >= 2 && inte <
        30) {
        inte = "VIP2";
        integral = 30;
        title = 'grade.2';
    }
    if (inte >= 30 && inte <
        150) {
        inte = "VIP3";
        integral = 150;
        title = 'grade.3';
    }
    if (inte >= 150 && inte <
        1500) {
        inte = "VIP4";
        integral = 1500;
        title = 'grade.4';
    }
    if (inte >= 1500 && inte <
        10000) {
        inte = "VIP5";
        integral = 10000;
        title = 'grade.5';
    }
    if (inte >= 10000 && inte <
        50000) {
        inte = "VIP6";
        integral = 50000;
        title = 'grade.6';
    }
    if (inte >= 50000 && inte <
        150000) {
        inte = "VIP7";
        integral = 150000;
        title = 'grade.7';
    }
    if (inte >= 150000 && inte <
        1000000) {
        inte = "VIP8";
        integral = 1000000;
        title = 'grade.8';
    }
    if (inte >= 1000000) {
        inte = "VIP9";
        integral = 1000000;
        title = 'grade.9';
    }
    obj.inte = inte;
    obj.integral = integral;
    obj.title = title;
    return obj;
}

//时间戳转日期
export const formatTimestampToDateTime = (timestamp) => {

    const date = new Date(parseInt(timestamp) * 1000);
    const year = date.getFullYear();

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


// 时间转换
export const timeConvers = (time) => {
    time = Number(time);
    time = time + 8 * 60 * 60 * 1000;
    // 设置使用中国时间
    let data = new Date(time * 1);
    let Y = data.getUTCFullYear(),
        M = data.getUTCMonth() + 1 >= 10 ? data.getUTCMonth() + 1 : "0" + (data.getUTCMonth() + 1),
        D = data.getUTCDate() >= 10 ? data.getUTCDate() : "0" + data.getUTCDate(),
        h = data.getUTCHours() < 10 ? "0" + data.getUTCHours() : data.getUTCHours(),
        min = data.getUTCMinutes() < 10 ? "0" + data.getUTCMinutes() : data.getUTCMinutes(),
        s = data.getUTCSeconds() < 10 ? "0" + data.getUTCSeconds() :
            data.getUTCSeconds();
    s = Number(s);
    time = {
        Y: Y,
        M: M,
        D: D,
        h: h,
        min: min,
        s: s
    }
    return time;
}

// 判断金额单位 obj.icon;
export const amountUnit = (_curr, language) => {
    // console.log(window._curr);
    // console.log(window._ui_language);
    let obj = {};
    let curr = _curr;
    let curr2 = 'Worldwide';
    let lang = language;
    if (curr == 'THB') {
        if (lang == 'zh' || lang == 'cn') {
            obj.unit = '銖';
        } else if (lang == 'th') {
            obj.unit = 'บาท';
        } else if (lang == 'vn') {
            obj.unit = 'Baht';
        } else if (lang == 'en') {
            obj.unit = 'Baht';
        }
        obj.icon = '฿';
    } else if (curr == 'VND') {
        if (lang == 'zh' || lang == 'cn') {
            obj.unit = '盾';
        } else if (lang == 'th') {
            obj.unit = 'เวียดนามโล่';
        } else if (lang == 'vn') {
            obj.unit = 'đồng';
        } else if (lang == 'vn') {
            obj.unit = 'Dong';
        }
        obj.icon = '₫';
    } else if (curr == 'CNY') {
        if (lang == 'zh' || lang == 'cn') {
            obj.unit = '元';
        } else if (lang == 'th') {
            obj.unit = 'หยวน';
        } else if (lang == 'vn') {
            obj.unit = 'nguyên tố';
        } else if (lang == 'en') {
            obj.unit = 'Yuan';
        }
        obj.icon = '￥';
    }
    else if (curr == 'ZAR') {
        if (lang == 'zh' || lang == 'cn') {
            obj.unit = '兰特';
        } else if (lang == 'th') {
            obj.unit = 'แรนด์';
        } else if (lang == 'vn') {
            obj.unit = 'Rand';
        } else if (lang == 'en') {
            obj.unit = 'Rand';
        }
        obj.icon = 'R';
    }
    if (curr2 == 'Worldwide') {
        if (lang == 'zh' || lang == 'cn') {
            obj.unit = '美元';
        } else if (lang == 'th') {
            obj.unit = 'ดอลลาร์';
        } else if (lang == 'vn') {
            obj.unit = 'đô la';
        } else if (lang == 'en') {
            obj.unit = 'Dollar';
        }
        obj.icon = '$';
    }
    // console.log(obj);
    return obj;
}


// 分单位转换 越南盾不显示分单位
export const unitconversion = (d, curr = '') => {
    if (!(typeof (d) == 'string')) {
        d = JSON.stringify(d);
    }
    d = d.replace(/\s+/g, "");

    let f = '',
        c = '';
    if (d.substr(0, 1) == "-") {
        c = "-";
        d = d.substr(1);
    }
    // console.log();
    if (d == null || d == 'null' || d == '') {
        f = '0.00';
    } else {
        if (d.length < 5) {
            f = (+d / 100).toFixed(2);
        } else {
            if (curr == "VND") {
                d = parseInt(d) + "";
            }
            let arr = [];
            arr[0] = d.slice(0, -2);
            arr[1] = d.slice(d.length - 2, d.length);
            f = arr.join('.')
        }
    }
    if (f.indexOf('..') != '-1') {
        f = f.replace('..', '.');
    }

    let a = f.split(".");
    let str = a[0];
    let spstr = str.split("").reverse();
    let q = 0,
        v = "";

    spstr.forEach(function (item) {
        if (q == 3) {
            v = item + "," + v;
            q = 0;
        } else {
            v = item + "" + v;;
        }
        q = q + 1;
    })
    f = v + "." + a[1];
    // console.log('f');
    // console.log(f);
    if (curr == "VND") {
        f = f.replace(/[.]/g, "-");
        f = f.replace(/[,]/g, "+");
        f = f.replace(/[-]/g, ",");
        f = f.replace(/[+]/g, ".");

        f = f.substring(0, f.length - 3);
    }
    // console.log('f');
    // console.log(f);
    return c + "" + f;
}


export const virtual = () => {
    return {
        BTC: 'bitcoin',
        ETH: 'ethereum',
        BCH: 'bitcoin-cash',
        UNI: 'uniswap',
        '1INCH': '1inch',
        LTC: 'litecoin',
        XRP: 'ripple',
        ETC: 'ethereum-classic',
        DOGE: 'dogecoin',
        FIL: 'filecoin',
        UMA: 'uma',
        AAVE: 'aave',
        BAL: 'balancer',
        CHZ: 'chiliz',
        HT: 'huobi-token',
        TRX: 'tron',
        EOS: 'eos',
        ADA: 'cardano',
        USDC: 'usd-coin',
        DOT: 'polkadot',
        XMR: 'monero',
        BSV: 'bitcoin-cash-sv',
        DASH: 'dash'
    };
}

// 交易类型
export const transactionType = (type) => {
    switch (type) {
        case "1":
            type = "tranytype.2";
            break;
        case "2":
            type = "tranytype.3";
            break;
        case "3":
            type = "tranytype.4";
            break;
        case "4":
            type = "comm.114";
            break;
        case "5":
            type = "tranytype.6";
            break;
        case "6":
            type = "tranytype.7";
            break;
        case "7":
            type = "tranytype.8";
            break;
        case "8":
            type = "tranytype.9";
            break;
        case "9":
            type = "tranytype.10";
            break;
        case "10":
            type = "tranytype.11";
            break;
        case "11":
            type = "tranytype.12";
            break;
        case "12":
            type = "tranytype.13";
            break;
        case "13":
            type = "tranytype.14";
            break;
        case "14":
            type = "comm.352";
            break;
        case "15":
            type = "tranytype.15";
            break;
        case "16":
            type = "tranytype.16";
            break;
        case "17":
            type = "tranytype.17";
            break;
        case "18":
            type = "tranytype.18";
            break;
        case "19":
            type = "tranytype.19";
            break;
        case "20":
            type = "tranytype.20";
            break;
        case "21":
            type = "tranytype.21";
            break;
        case "22":
            type = "tranytype.22";
            break;
        case "23":
            type = "tranytype.23";
            break;
        case "24":
            type = "tranytype.24";
            break;
        case "25":
            type = "tranytype.25";
            break;
        case "26":
            type = "tranytype.26";
            break;
        case "27":
            type = "tranytype.27";
            break;
        case "28":
            type = "tranytype.28";
            break;
        case "30":
            type = "tranytype.30";
            break;
    }
    return type;
}



// 彩种编码
export const lotteryCode = (code, data) => {
    let obj = {};
    switch (code) {
        case "幸运快3":
        case '7':
            obj.name = 'l_name.1';
            obj.class = ' L_K3';
            break;
        case "福运快3":
        case '17':
            obj.name = 'l_name.2';
            obj.class = ' L_K3';
            break;
        case "欢乐快3":
        case '8':
            obj.name = 'l_name.4';
            obj.class = ' L_K3';
            break;
        case "好运快3":
        case '9':
            obj.name = 'l_name.3';
            obj.class = ' L_K3';
            break;
        case '201':
            obj.name = '';
            obj.class = ' L_K3';
            break;
        case '202':
            obj.name = '';
            obj.class = ' L_K3';
            break;
        case '74':
            obj.name = 'l_name.107';
            obj.class = ' L_K3';
            break;
        case "幸运pk10":
        case '10':
            obj.name = 'l_name.5';
            obj.class = ' L_PK10';
            break;
        case "好运pk10":
        case '11':
            obj.name = 'l_name.7';
            obj.class = ' L_PK10';
            break;
        case "欢乐pk10":
        case '12':
            obj.name = 'l_name.8';
            obj.class = ' L_PK10';
            break;
        case "鸿运PK10":
        case '55':
            obj.name = 'l_name.6';
            obj.class = ' L_PK10';
            break;
        case "幸运飞艇":
        case '29':
            obj.name = 'l_name.9';
            obj.class = ' L_FT';
            break;
        case "幸运28":
        case '13':
            obj.name = 'l_name.10';
            obj.class = ' L_BJ28';
            break;
        case "鸿运28":
        case '60':
            obj.name = 'l_name.11';
            obj.class = ' L_BJ28';
            break;
        case "好运28":
        case '14':
            obj.name = 'l_name.12';
            obj.class = ' L_BJ28';
            break;
        case "欢乐28":
        case '15':
            obj.name = 'l_name.13';
            obj.class = ' L_BJ28';
            break;
        case "幸运3D":
        case '50':
            obj.name = 'l_name.14';
            obj.class = ' L_FC3D';
            break;
        case "鸿运3D":
        case '51':
            obj.name = 'l_name.15';
            obj.class = ' L_FC3D';
            break;
        case "好运3D":
        case '52':
            obj.name = 'l_name.16';
            obj.class = ' L_FC3D';
            break;
        case "欢乐3D":
        case '53':
            obj.name = 'l_name.17';
            obj.class = ' L_FC3D';
            break;
        case "幸运排列3":
        case '67':
            obj.name = 'l_name.18';
            obj.class = ' L_PL35';
            break;
        case "鸿运排列3":
        case '68':
            obj.name = 'l_name.19';
            obj.class = ' L_PL35';
            break;
        case "好运排列3":
        case '69':
            obj.name = 'l_name.20';
            obj.class = ' L_PL35';
            break;
        case "欢乐排列3":
        case '70':
            obj.name = 'l_name.21';
            obj.class = ' L_PL35';
            break;
        case "股彩韩国":
        case '88':
            obj.name = 'l_name.27';
            obj.class = ' L_kr3d';
            break;
        case "股彩日本":
        case '89':
            obj.name = 'l_name.28';
            obj.class = ' L_jp3d';
            break;
        case "股彩香港":
        case '90':
            obj.name = 'l_name.29';
            obj.class = ' L_hk3d';
            break;
        case "股彩中国":
        case '91':
            obj.name = 'l_name.30';
            obj.class = ' L_cn3d';
            break;
        case "股彩台湾":
        case '92':
            obj.name = 'l_name.31';
            obj.class = ' L_tw3d';
            break;
        case "股彩新加坡":
        case '93':
            obj.name = 'l_name.32';
            obj.class = ' L_sg3d';
            break;
        case "股彩埃及":
        case '94':
            obj.name = 'l_name.33';
            obj.class = ' L_eg3d';
            break;
        case "股彩德国":
        case '95':
            obj.name = 'l_name.34';
            obj.class = ' L_de3d';
            break;
        case "股彩英国":
        case '96':
            obj.name = 'l_name.35';
            obj.class = ' L_uk3d';
            break;
        case "股彩俄罗斯":
        case '97':
            obj.name = 'l_name.36';
            obj.class = ' L_ru3d';
            break;
        case "股彩印度":
        case '98':
            obj.name = 'l_name.37';
            obj.class = ' L_in3d';
            break;
        case "股彩美国":
        case '99':
            obj.name = 'l_name.38';
            obj.class = ' L_us3d';
            break;
        case "股彩马来西亚":
        case '100':
            obj.name = 'l_name.39';
            obj.class = ' L_my3d';
            break;
        case "股彩老挝":
        case '101':
            obj.name = 'l_name.40';
            obj.class = ' L_la3d';
            break;
        case "股彩越南":
        case '102':
            obj.name = 'l_name.41';
            obj.class = ' L_vn3d';
            break;
        case "幸运⑥合彩":
        case '33':
            obj.name = 'l_name.88';
            obj.class = ' L_LHC';
            break;
        case "鸿运⑥合彩":
        case '59':
            obj.name = 'l_name.89';
            obj.class = ' L_LHC';
            break;
        case "好运⑥合彩":
        case '32':
            obj.name = 'l_name.90';
            obj.class = ' L_LHC';
            break;
        case "欢乐⑥合彩":
        case '31':
            obj.name = 'l_name.91';
            obj.class = ' L_LHC';
            break;
        case "香港⑥合彩":
        case '58':
            obj.name = 'l_name.50';
            obj.class = ' L_LHC';
            break;
        case "腾讯分分彩":
        case '39':
            obj.name = 'l_name.93';
            obj.class = ' L_SSC';
            break;
        case "幸运时时彩":
        case '36':
            obj.name = 'l_name.94';
            obj.class = ' L_SSC';
            break;
        case "鸿运时时彩":
        case '57':
            obj.name = 'l_name.95';
            obj.class = ' L_SSC';
            break;
        case "好运时时彩":
        case '35':
            obj.name = 'l_name.96';
            obj.class = ' L_SSC';
            break;
        case "欢乐时时彩":
        case '34':
            obj.name = 'l_name.97';
            obj.class = ' L_SSC';
            break;
        case "幸运泰国":
        case '77':
            obj.name = 'l_name.23';
            obj.class = ' L_thai';
            break;
        case "鸿运泰国":
        case '78':
            obj.name = 'l_name.24';
            obj.class = ' L_thai';
            break;
        case "好运泰国":
        case '79':
            obj.name = 'l_name.25';
            obj.class = ' L_thai';
            break;
        case "欢乐泰国":
        case '80':
            obj.name = 'l_name.26';
            obj.class = ' L_thai';
            break;
        case "泰国彩票":
        case '81':
            obj.name = 'l_name.105';
            obj.class = ' L_thai';
            break;
        case "幸运红绿":
        case '103':
            obj.name = 'l_name.108';
            obj.class = ' L_wg';
            break;
        case "鸿运红绿":
        case '104':
            obj.name = 'l_name.109';
            obj.class = ' L_wg';
            break;
        case "好运红绿":
        case '105':
            obj.name = 'l_name.110';
            obj.class = ' L_wg';
            break;
        case "幸运BTC":
        case '106':
            obj.name = 'l_name.112';
            obj.class = ' L_btc';
            break;
        case "鸿运BTC":
        case '107':
            obj.name = 'l_name.113';
            obj.class = ' L_btc';
            break;
        case "好运BTC":
        case '108':
            obj.name = 'l_name.114';
            obj.class = ' L_btc';
            break;
        case "幸运龙虎":
        case '109':
            obj.name = 'l_name.116';
            obj.class = ' L_dt';
            break;
        case "鸿运龙虎":
        case '110':
            obj.name = 'l_name.117';
            obj.class = ' L_dt';
            break;
        case "好运龙虎":
        case '111':
            obj.name = 'l_name.118';
            obj.class = ' L_dt';
            break;
        case "幸运北部":
        case '112':
            obj.name = 'l_name.121';
            obj.class = ' L_vnxoso';
            break;
        case "鸿运北部":
        case '113':
            obj.name = 'l_name.122';
            obj.class = ' L_vnxoso';
            break;
        case "好运北部":
        case '114':
            obj.name = 'l_name.123';
            obj.class = ' L_vnxoso';
            break;
        case "幸运中部":
        case '115':
            obj.name = 'l_name.124';
            obj.class = ' R_vnxoso';
            break;
        case "鸿运中部":
        case '116':
            obj.name = 'l_name.125';
            obj.class = ' R_vnxoso';
            break;
        case "好运中部":
        case '117':
            obj.name = 'l_name.126';
            obj.class = ' R_vnxoso';
            break;
        case "幸运南部":
        case '118':
            obj.name = 'l_name.127';
            obj.class = ' Z_vnxoso';
            break;
        case "鸿运南部":
        case '119':
            obj.name = 'l_name.128';
            obj.class = ' Z_vnxoso';
            break;
        case "好运南部":
        case '120':
            obj.name = 'l_name.129';
            obj.class = ' Z_vnxoso';
            break;
        case "官方北部彩":
        case '121':
            obj.name = 'l_name.130';
            obj.class = ' L_vnxoso';
            break;
        case "官方中部彩":
        case '122':
            obj.name = 'l_name.131';
            obj.class = ' R_vnxoso';
            break;
        case "官方南部彩":
        case '123':
            obj.name = 'l_name.132';
            obj.class = ' Z_vnxoso';
            break;
        case '124':
            obj.name = 'l_name.134';
            obj.class = ' virtual';
            break;
        case '125':
            obj.name = 'l_name.135';
            obj.class = ' virtual';
            break;
        case '126':
            obj.name = 'l_name.136';
            obj.class = ' virtual';
            break;

    }
    return obj;
}

import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style'
// import HTML from 'react-native-render-html';
import { useWindowDimensions } from 'react-native';

const ArticleDetail = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();


  const htmlContent = `<div style="color:#fff">
  
  </div>`;

  const renderersProps = {
    text: {
      style: styles.content,
    },
  };

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <Text style={styles.title}> </Text>
        {/* <HTML
          source={{ html: htmlContent }}
          renderersProps={renderersProps}
          contentWidth={width} /> */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: commonStyle.bgColor,
  },
  contentContainer: {
    width: '90%',
  },
  title: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 18,
    marginBottom: 10,
  },
  content: {
    color: '#fff',
    fontSize: 16,
  },
});

export default ArticleDetail;
import React, { useState, useEffect, useRef, useReducer } from 'react';
import { View, Text, Image, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, RefreshControl, Dimensions } from 'react-native';
import ActiveTabItem from '../components/activeTabItem'
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
import { FontAwesome } from '@expo/vector-icons';
import { betcenterBetrecord, betcenterBetNumberRecord, servertime } from '../api/apis';
import { transactionType, lotteryCode, virtual, unitconversion } from '../components/util'
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import ProgressBar from 'react-native-progress-bar-animated';

const App = () => {

    const { t } = useTranslation();

    if(Dimensions.get('window').width > 500){
        Dimensions.get('window').width = 500
    }
    
    const { width } = Dimensions.get('window');

    const navigation = useNavigation();

    const [data, setData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(0);
    const [emptyShow, setEmptyShow] = useState(false);
    const [timestamp, setTimestamp] = useState(Math.floor(Date.now() / 1000));
    const [waitingData, setWaitingData] = useState([]); //等待开奖的数据

    useEffect(() => {
        // 初始化数据
        handleRefresh()
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimestamp(Math.floor(Date.now() / 1000));
            updateElement()
        }, 1500);

        return () => {
            clearInterval(interval); // 在组件卸载时清除定时器
        };
    }, [waitingData, data, timestamp]);

    const updateElement = async () => {
        console.log(waitingData);
        if (waitingData.length > 0) {
            const joinedString = waitingData.join(',');
            betcenterBetNumberRecord(joinedString).then(response => {
                if (response.status == 1) {
                    const resData = response.Data1.item
                    const systime = response.Data1.systime
                    const updateData = data.map(item => {
                        const matchedElement = resData.find(element => element.Bet_slip_number == item.Bet_slip_number);
                        if (matchedElement) {
                            const Lotterly_code_timestamp = getLotterlyCodeTimestamp(matchedElement.Lotterly_code);
                            let totalTime = Number(matchedElement.Bet_time) + Lotterly_code_timestamp;

                            //测试
                            // matchedElement.State_Winning = 3
                            // totalTime = systime + 30

                            const stateWinning = matchedElement.State_Winning != 3
                                ? matchedElement.State_Winning
                                : (totalTime <= systime ? 0 : matchedElement.State_Winning);

                            // 未到时间设置进度条百分比
                            let progress = 0
                            if (matchedElement.State_Winning == 3) {
                                progress = ((totalTime - systime) / Lotterly_code_timestamp) * 100;
                                // console.log(totalTime, systime, Lotterly_code_timestamp)
                                // console.log('进度条', Math.floor(progress));
                            }

                            if (stateWinning != 3 && stateWinning != 0) {
                                removeWaitingData(matchedElement.Bet_slip_number)
                            }

                            item.times = totalTime - systime
                            item.State_Winning2 = stateWinning
                            item.progress = Math.floor(progress);
                            item.Winning_amount2 = matchedElement.Winning_amount
                        }
                        return item;
                    });
                    setData(updateData);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    };

    const getLotterlyCodeTimestamp = lotterlyCode => {
        switch (Number(lotterlyCode)) {
            case 124:
                return 60;
            case 125:
                return 180;
            case 126:
                return 300;
            default:
                return 0;
        }
    };


    // 已经获取到的待开奖数据删除
    const removeWaitingData = (elementToRemove) => {
        const newArray = waitingData.filter((element) => element !== elementToRemove);
        setWaitingData(newArray)
    }

    // 刷新
    const handleRefresh = () => {
        console.log('刷新')
        setRefreshing(true)
        setPage(0)
        setTimeout(() => {
            record()
        }, 1000);
    };

    // 加载更多数据
    const handleLoadMore = () => {
        console.log('加载更多')
        setLoadingMore(true);
        // setPage(parseInt(page) + 1)
        setTimeout(() => {
            record()
        }, 1000);
    };

    const betDetail = (item) => {
        // console.log(item)
        navigation.navigate('BetRecordDetail', { item: item });
    }

    //获取记录
    const record = async () => {
        const nextPage = page + 1;
        setPage(nextPage)
        await betcenterBetrecord(nextPage, '').then(response => {
            setRefreshing(false);
            setLoadingMore(false);
            if (response.status == 1) {
                let wait_array = [];
                const resData = response.Data1.item
                const systime = response.Data1.systime
                const updateData = resData.map(data => {
                    // const betAmount = data.outMoney == '' || data.outMoney == 0 ? '' : (data.Bet_amount / 100).toLocaleString('en-US', {
                    //     minimumFractionDigits: 2,
                    //     maximumFractionDigits: 2,
                    // });
                    const lotterlyCode = lotteryCode(data.Lotterly_code)

                    // 获取虚拟货币代码的数组
                    const virtualCurrencyCodes = Object.keys(virtual())

                    // 要检查的字符串
                    const searchString = data.PlayName;

                    let name = ''
                    // 使用 Array.includes 检查字符串是否在虚拟货币代码的数组中
                    if (virtualCurrencyCodes.includes(searchString)) {
                        name = data.PlayName + ' '
                    } else {
                        name = ''
                    }

                    // 测试
                    // if (data.Bet_slip_number == 'TZ231201190327485105898') {
                    //     data.State_Winning = 3
                    // }
                    // data.State_Winning = 3

                    const Lotterly_code_timestamp = getLotterlyCodeTimestamp(data.Lotterly_code);
                    let totalTime = Number(data.Bet_time) + Lotterly_code_timestamp;
                    let progress = 0
                    if (data.State_Winning == 3) {
                        wait_array.push(data.Bet_slip_number)
                        progress = Math.floor((totalTime - systime) / Lotterly_code_timestamp * 100)
                    }
                    data.progress = progress
                    data.name = name
                    data.Lotterly_code = t(transactionType(lotterlyCode.name))
                    data.times = totalTime - systime
                    return data
                });

                // console.log('wait_array')
                // console.log(wait_array)

                const concat_wait = waitingData.concat(wait_array)

                setWaitingData([...new Set(concat_wait)])

                const dataList = data.concat(updateData)

                if (nextPage > 1) {
                    setData(dataList)
                } else {
                    setData(updateData)
                    if (dataList.length == 0) {
                        setEmptyShow(true)
                    }
                }
            }
        }).catch(error => {
            setRefreshing(false);
            setLoadingMore(false);
            console.error(error);
        });
    };

    const renderItem = ({ item }) => (
        <><View style={styles.itemContainer}>
            <TouchableOpacity onPress={() => betDetail(item)}>
                <View style={styles.itemRowContainer}>
                    <View><Text style={styles.itemTextGreen}>{item.name}{item.Lotterly_code}</Text></View>
                    <View>
                        {item.BetNum == 'all' ? (
                            <Text style={styles.itemTextGray}>
                                {unitconversion(item.Bet_amount)} {t('all')}
                            </Text>
                        ) : (
                            <Text style={styles.itemTextGray}>
                                {unitconversion(item.Bet_amount)} {item.BetNum == 'up' ? t('buyUp') : t('buyDown')}
                            </Text>
                        )}
                    </View>
                </View>
                <View style={styles.itemRowContainer}>
                    <View><Text style={styles.itemTextGray}>{item.betting_issuseNo}</Text></View>

                    {item.State_Winning2 ? <View>
                        {item.State_Winning2 == '3' &&
                            <Text style={styles.itemTextGray}>
                                {t('waitingLottery')}
                            </Text>}
                        {item.State_Winning2 == '2' &&
                            <Text style={styles.itemTextRed}>
                                {t('notWinningTheLottery')}
                            </Text>}
                        {item.State_Winning2 == '1' &&
                            <Text style={styles.itemTextGreen}>
                                +{unitconversion(item.Winning_amount2)}
                            </Text>}
                        {item.State_Winning2 == '0' &&
                            <Text style={styles.itemTextGray}>
                                {t('settling')}
                            </Text>}
                    </View> : <View>
                        {item.State_Winning == '3' &&
                            <Text style={styles.itemTextGray}>
                                {t('waitingLottery')}
                            </Text>}
                        {item.State_Winning == '2' &&
                            <Text style={styles.itemTextRed}>
                                {t('notWinningTheLottery')}
                            </Text>}
                        {item.State_Winning == '1' &&
                            <Text style={styles.itemTextGreen}>
                                +{unitconversion(item.Winning_amount)}
                            </Text>}
                        {item.State_Winning == '0' &&
                            <Text style={styles.itemTextGray}>
                                {t('settling')}
                            </Text>}
                    </View>}


                </View>
            </TouchableOpacity>
        </View>
            {item.State_Winning == 3 && <ProgressBar
                width={width}
                height={2}
                borderRadius={5}
                borderWidth={0}
                maxValue={100}
                value={item.progress}
                color="#6CC644"
                backgroundColorOnComplete="#6CC644"
                backgroundColor={commonStyle.green} />}
        </>
    );

    return (
        <View style={styles.container}>
            {emptyShow && <View style={{ justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <Text style={{ color: '#fff' }}>{t('noData')}</Text>
            </View>}
            {/* <Image style={styles.image} source={require('../../assets/imgs/common/empty.png')} /> */}

            {/* <View><Text style={{ color: '#fff' }}>时间戳: {timestamp}</Text></View> */}
            <FlatList
                data={data}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                onEndReached={!loadingMore && handleLoadMore}
                onEndReachedThreshold={0.1}
                ListFooterComponent={loadingMore && <ActivityIndicator color={commonStyle.green} />}
            />


        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor
    },
    tabContainer: {
        flexDirection: 'row',
        paddingVertical: 10,
        backgroundColor: '#1B1D29',
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 10,
    },
    tabText: {
        color: commonStyle.textGreenColor,
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemContainer: {
        flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#1B1D29',
        marginTop: 5,
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    activeTabItem: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {

    }

});

export default App;